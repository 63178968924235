/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
/* ---Header Top--- */
.dropdown{
    &-wrap{
        display: flex;
        align-items: center;
        li{
            .ht-btn{
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 0;
                padding-right: 0;
                &:after{
                    font-family: FontAwesome;
                    border: 0;
                    content: "\f078";
                    font-size: 10px;
                    border-right: 1px solid $border-color;
                    padding-right: 15px;
                    margin-right: 15px;
                }
            }
        }
    }
    &-menu{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        animation-fill-mode: forwards;
        transform: translateY(20px);
        transition: $baseTransition;
        transform-origin: top;
        left: 0 !important;
        visibility: hidden;
        border: 0;
        opacity: 0;
        display: block;
        height: fit-content;
        @include media-breakpoint-down(lg){
            min-width: 7rem;
            left: auto !important;
            right: 15px !important;
        }
        &.right-side{
            left: auto !important;
            right: 15px !important;
        }
        &.show {
            transform: translate(0, 0) !important;
            top: 100% !important;
            visibility: visible;
            opacity: 1;
        }
        li{
            a{
                font-size: 14px;
            }
            &:hover{
                > a{
                    &.dropdown-item{
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
            .dropdown-item{
                &:focus{
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}
.header{
    &-top{
        &-right{
            display: flex;
            justify-content: flex-end;
            .shipping{
                letter-spacing: 0.8px;
                font-size: 14px;
                span{
                    color: $primary;
                }
            }
        }
    }
}

/* ---Header Middle--- */
.header{
    &-middle{
        &-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

/* ---Main Header--- */
.main{
    &-nav{
        > ul{
            display: flex;
            justify-content: center;
            > li{
                display: inline-block;
                &:not(:last-child){
                    padding-right: 30px;
                }
                a{
                    color: #555252;
                }
                > a{
                    line-height: 90px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    text-transform: uppercase;
                    i{
                        margin-left: 5px;
                        font-size: 25px;
                        transition: $baseTransition;
                    }
                }
                &:hover{
                    > a{
                        color: $primary;
                        i{
                            transform: rotate(180deg);
                        }
                    }
                    > .drop-menu{
                        opacity: 1;
                        visibility: visible;
                        transform: perspective(600px) rotateX(0deg);
                        > li{
                            &:hover{
                                > .drop{
                                    &-menu{
                                        opacity: 1;
                                        visibility: visible;
                                        transform: perspective(600px) rotateX(0deg);
                                        > li{
                                            &:hover{
                                                > .drop{
                                                    &-menu{
                                                        opacity: 1;
                                                        visibility: visible;
                                                        transform: perspective(600px) rotateX(0deg);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Drop Menu--- */

.drop{
    &-holder{
        position: relative;
    }
    &-menu{
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        background-color: $white;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        top: 100%;
        left: 0;
        transition: $baseTransition;
        width: 250px;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        text-align: left;
        z-index: 2;
        transform-origin: 0 0 0;
        transform: perspective(600px) rotateX(-90deg);
        @include media-breakpoint-down(xl){
            left: auto;
            right: 0;
        }
        > li{
            &:not(:last-child){
                padding-bottom: 10px;
            }
        }
        li{
            &:hover{
                > a{
                    color: $primary;
                }
            }
        }
        > li{
            > .drop{
                &-menu{
                    top: 0;
                    left: 100%;
                    right: auto;
                    > li{
                        > .drop{
                            &-menu{
                                top: 100%;
                                left: auto;
                                right: calc(100% + 30px);
                                @include media-breakpoint-up(xxl){
                                    top: 0;
                                    left: 100%;
                                    right: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Sub Dropdown--- */
.sub-dropdown{
    &.drop-menu{
        top: 30px;
        left: auto;
        right: 100%;
    }
    &-holder{
        &:hover{
            .sub-dropdown{
                &.drop-menu{
                    top: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }
            > a{
                color: $primary;
            }
        }
    }
}

/* ---Megamenu--- */
.megamenu{
    display: flex;
    width: 100%;
    padding: 0;
    padding: 40px;
    &-holder{
        position: static;
    }
    .title{
        text-transform: uppercase;
        margin-bottom: 30px;
        font-size: 18px;
        color: #383838;
        font-weight: 500;
        display: block;
        @include media-breakpoint-only(lg){
            font-size: 17px;
        }
    }
    &.drop-menu{
        > li{
            padding-bottom: 0;
        }
    }
    > li{
        width: 25%;
        &:not(:last-child){
            border-right: 1px solid #e7e7e7;
            padding-right: 30px;
            margin-right: 30px;
        }
        > ul{
            li{
                &:not(:last-child){
                    padding-bottom: 30px;
                }
                a{
                    display: block;
                    transition: $baseTransition;
                    font-size: 14px;
                }
            }
        }
        .banner{
            img{
                object-position: center center;
                width: 100%;
            }
        }
    }
}

/* ---Header Right--- */
.header-right{
    > ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        > li{
            position: relative;
            cursor: pointer;
            &:not(:last-child){
                padding-right: 15px;
            }
            > a{
                i{
                    font-size: 23px;
                    vertical-align: middle;
                    &:before{
                        font-weight: 500;
                    }
                }
            }
            &.minicart{
                &-wrap{
                    padding-right: 12px;
                }
            }
            .minicart{
                &-btn{
                    .quantity{
                        background-color: $primary;
                        border-radius: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: $white;
                        display: block;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        position: absolute;
                        top: -5px;
                        right: 0;
                    }
                }
            }
        }
        .btn{
            display: flex;
            i{
                color: $dark;
                font-size: 24px;
            }
            &:after{
                border: 0;
            }
        }
    }
}

/* ---Header Logo--- */
.header-logo{
    display: flex;
    align-items: center;
    @media (max-width: 479px) {
        width: 140px;
    }
}

/* ---Header Sticky--- */
.sticky{
    animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
    background-color: $white;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
    display: block;
    .header-logo-wrap{
        top: 0;
        height: 100%;
    }
}